import React from 'react';

const Maintenance = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white flex flex-col items-center justify-center p-4">
      <div className="max-w-2xl w-full bg-white rounded-xl shadow-lg p-8 text-center border border-gray-100">
        <div className="flex justify-center mb-6">
          <div className="text-5xl">
            📄✨
          </div>
        </div>
        <h1 className="text-4xl font-bold text-gray-800 mb-4">
          We are currently upgrading our resume building platform to serve you better.
        </h1>
        <div className="space-y-4">
          <p className="text-xl text-gray-600">
            We're currently upgrading our resume building platform to serve you better.
          </p>
          <div className="bg-blue-50 p-4 rounded-lg">
            <h2 className="text-lg font-semibold text-gray-800 mb-2">Coming Soon:</h2>
            <ul className="text-gray-600 space-y-2">
              <li>✓ Enhanced AI-powered resume analysis</li>
              <li>✓ Improved keyword optimization</li>
              <li>✓ Advanced customization options</li>
              <li>✓ Better ATS compatibility</li>
            </ul>
          </div>
          <p className="text-gray-500">
            We appreciate your patience as we work to make your resume stand out even more.
          </p>
          <p className="text-sm text-blue-600 font-medium">
            Expected return: Coming Soon
          </p>
        </div>
      </div>
    </div>
  );
};

export default Maintenance; 